export const _configOrganismes = [
  {
    id: 0,
    code: '',
    label: 'Non précisé',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '01 82 88 45 63'
  },
  {
    id: 1,
    code: 'ATL',
    label: 'ICF ATLANTIQUE',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '02 46 88 00 56'
  },
  {
    id: 2,
    code: 'CHA',
    label: 'CHATEAU D\'ARCY',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '03 28 55 07 16'
  },
  {
    id: 3,
    code: 'ICF',
    label: 'S I C F',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '03 28 55 07 16'
  },
  {
    id: 4,
    code: 'NGF',
    label: 'ICF NOVEDIS GES FONCIERE',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoNovPM.png',
    path: 'assets/images/logos/',
    altText: 'Logo Novedis PM',
    tel: '03 28 55 07 16'
  },
  {
    id: 5,
    code: 'NOV',
    label: 'ICF NOVEDIS',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoNovPM.png',
    path: 'assets/images/logos/',
    altText: 'Logo Novedis PM',
    tel: '01 82 88 45 62'
  },
  {
    id: 6,
    code: 'PAR',
    label: 'SCI PARADIS CLICHY',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '02 46 88 00 56'
  },
  {
    id: 7,
    code: 'RNE',
    label: 'ICF NORD-EST',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '03 72 88 05 18'
  },
  {
    id: 8,
    code: 'RSE',
    label: 'ICF SUD-EST MEDITERRANEE',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '04 81 68 52 45'
  },
  {
    id: 9,
    code: 'SAB',
    label: 'ICF LA SABLIERE',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '01 82 88 45 63'
  },
  {
    id: 10,
    code: 'TAI',
    label: 'Trans\'Actif Immobilier',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '03 28 55 07 16'
  },
  {
    id: 11,
    code: 'VES',
    label: 'FONCIERE VESTA',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/default.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/default.json',
    logo: 'LogoICF.png',
    path: 'assets/images/logos/',
    altText: 'Logo ICF Habitat',
    tel: '03 28 55 07 16'
  },
  {
    id: 12,
    code: 'BDF',
    label: 'Banque de France',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/bdf.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/bdf.json',
    logo: 'LogoNovPM.png',
    path: 'assets/images/logos/',
    altText: 'Logo Novedis PM',
    tel: '03 28 55 07 16'
  },
  {
    id: 13,
    code: 'AUB',
    label: 'AUBERGE DE MONTMARTRE',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/aub.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/aub.json',
    logo: 'LogoNovPM.png',
    path: 'assets/images/logos/',
    altText: 'Logo Novedis PM',
    tel: '03 28 55 07 16'
  },
  {
    id: 14,
    code: 'GCO',
    label: 'SNCF GARES & CONNEXIONS',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/gco.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/gco.json',
    logo: 'LogoNovPM.png',
    path: 'assets/images/logos/',
    altText: 'Logo Novedis PM',
    tel: '03 28 55 07 16'
  },
  {
    id: 15,
    code: 'IMO',
    label: 'SA SNCF',
    fichierConfigInfosPratiques: '../../../../../assets/config/rubriques/infos-pratiques/imo.json',
    fichierConfigLiensUtiles: '../../../../../assets/config/rubriques/liens-utiles/imo.json',
    logo: 'LogoNovPM.png',
    path: 'assets/images/logos/',
    altText: 'Logo Novedis PM',
    tel: '03 28 55 07 16'
  }
]
